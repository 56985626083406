<!--
 * @Date: 2021-04-26 11:13:09
 * @LastEditTime: 2023-02-05 10:15:00
 * @Description: 培训card  
-->

<template>
  <div
    :class="[
      'item-wrapper',
      borderBottom ? '' : 'no-border',
      data.approveType !== undefined && data.approveType !== 3
        ? 'job-item-delete'
        : '',
    ]"
    @click="handleClick"
  >
    <div class="content">
      <div class="p1">
        <div class="job-name ellipsis">{{ data.jobName || "" }}</div>
        <div class="money ellipsis">{{ getSalaryText() }}</div>
      </div>

      <div class="p2 ellipsis">
        <span class="company-name">{{ data.cmpyName || "" }}</span>
      </div>

      <div
        class="p3"
        v-if="data.reducingConditions && data.reducingConditions.length"
      >
        <template v-for="(item, index) in data.reducingConditions">
          <div class="tag" v-if="index < 3" :key="index">
            {{ item }}
          </div>
        </template>
      </div>
      <div class="p4 ellipsis">
        <span>{{ data.cityName || "" }} {{ data.countyName || "" }}</span>
      </div>
    </div>
    <div
      v-if="data.approveType !== undefined && data.approveType !== 3"
      class="delete-message"
    >
      <span>已下架</span>
    </div>
  </div>
</template>

<script>
import { getSalaryText } from "@/utils";
export default {
  data() {
    return {
      fanfeiImg:
        "https://hrbd-mp.oss-cn-beijing.aliyuncs.com/prod/img/fanfei.png",
      defaultImg:
        "https://hrbd-mp.oss-cn-beijing.aliyuncs.com/prod/img/default.png",
    };
  },
  props: {
    data: Object,
    borderBottom: {
      default: true,
      type: Boolean,
    },
    isConcennBtnShow: {
      type: Boolean,
      default: true,
    },
  },
  filters: {
    numToIntW: (num) => {
      if (!num || num == "undefined") return 0;
      if (num.toString().length > 4) {
        return (num / 10000).toFixed(1) + "w";
      } else {
        return Number(num).toFixed(0) || 0;
      }
    },
    //数字取整
    numToInt: (value) => {
      if (!value) return 0;
      return Number(value).toFixed(0);
    },
  },
  computed: {},
  mounted() {},
  methods: {
    handleClick() {
      // 兼容 人力资源Title 显示
      const name = this.$route.name;
      this.$router.push({
        path: "/jobDetail",
        query: {
          jobCode: this.data.jobCode,
          originPath: name,
        },
      });
    },
    getSalaryText() {
      return getSalaryText(this.data);
    },
  },
};
</script>
<style lang="scss" scoped>
.item-wrapper {
  display: flex;
  box-sizing: border-box;
  font-size: 0.3rem;
  color: #333;
  background-color: #fff;
  overflow: hidden;
  box-sizing: border-box;
  padding-top: 0.2rem;
}
.img-wrapper {
  width: 2.36rem;
  height: 2.36rem;
  flex: 0 0 2.36rem;
  position: relative;

  .img {
    width: 100%;
    height: 100%;
    border-radius: 0.16rem;
    overflow: hidden;
  }
  .badge {
    position: absolute;
    top: -0.1rem;
    left: 0.1rem;
    min-width: 0.73rem;
    background-color: #fff;
    border-radius: 0 0 0.08rem 0.08rem;
    box-shadow: 1px 1px 3px 0px rgba(103, 103, 103, 0.5);
    z-index: 99;
    display: block;
    transform: transition3d(0, 0, 0);
    will-change: transform;
    .badge-content {
      text-align: center;
    }
    .badge-text {
      color: #fff;
      height: 0.28rem;
      background: linear-gradient(
        48deg,
        #3c37d2 0%,
        #5954d6 50%,
        #f94363 90%,
        #ff4451 100%
      );
      border-radius: 0 0 0.08rem 0.08rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .badge-num {
      font-size: 0.24rem;
      color: #ff2731;
      line-height: 0.28rem;
      padding: 0 2px;
    }
    .fanfei-img {
      width: 0.4rem;
      height: 0.2rem;
    }
  }
}
.content {
  flex: 1;
  overflow: hidden;
  border-radius: 0 0 0.16rem 0.16rem;
  // margin-left: 0.2rem;
  .ellipsis {
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
  }
}
.p1 {
  margin-bottom: 0.1rem;
  margin-top: 0.04rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .job-name {
    color: #333;
    font-size: 0.34rem;
    line-height: 0.4rem;
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
    flex: 1;
  }
  .money {
    font-size: 0.34rem;
    font-weight: bold;
    color: #3c37d2;
    line-height: 0.48rem;
    white-space: nowrap;
    word-break: keep-all;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50%;
  }
}
.p2 {
  font-size: 0.24rem;
  color: #999999;
  line-height: 0.33rem;
  margin-bottom: 0.15rem;
}

.p3 {
  margin-bottom: 0.16rem;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  .tag {
    margin-right: 0.2rem;
    padding: 0.01rem 0.08rem;
    border-radius: 0.08rem;
    border: 1px solid #999;
    font-size: 0.2rem;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    line-height: 0.28rem;
    &:nth-last-of-type(1) {
      margin-right: 0;
    }
  }
}

.p4 {
  color: #999;
  font-size: 0.2rem;
  color: #999999;
  line-height: 0.28rem;
  margin-bottom: 0.09rem;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
}

.job-item-delete {
  position: relative;
  opacity: 0.5;
  .delete-message {
    position: absolute;
    left: 0rem;
    top: 0.97rem;
    width: 100%;
    height: 0.48rem;
    font-size: 0.34rem;
    line-height: 0.48rem;
    text-align: center;
    color: #fff;
  }
}
</style>
