<!--
 * @Description: 招聘列表  含无数据显示、广告banner
-->

<template>
  <div class="wrapper" :class="noData ? 'no-data-wrapper' : ''">
    <div v-if="data.length > 0" class="list">
      <div v-for="(item, index) in data" :key="index">
        <div class="job-item-wrapper" :key="item.id">
          <job-item
            :borderBottom="curentAd(index + 1) ? false : true"
            :isConcennBtnShow="isConcennBtnShow"
            :data="item"
          ></job-item>
        </div>
      </div>
    </div>

    <div v-if="noData && !loading" class="no-data">
      <no-data class="no-wrapper"></no-data>
    </div>
  </div>
</template>

<script>
import JobItem from "@/components/JobItem";
export default {
  data() {
    return {};
  },
  components: { JobItem },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    noMore: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      default: "flow",
      type: String,
    },
    noMoreBg: {
      default: "#f0f0f0",
      type: String,
    },
    noData: {
      type: Boolean,
      default: false,
    },
    adList: {
      type: Array,
      default: () => {
        return []; //[{id ,index 排序,url 跳转地址, img 图片}]
      },
    },
    isConcennBtnShow: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {},
  computed: {
    // adListT() {
    //   const list = this.adList.map((el) => {
    //     return {
    //       ...el,
    //       // index: el.index % 2 === 1 ? el.index + 1 : el.index  //瀑布流时index奇数+1变成偶数
    //       index: el.index,
    //     };
    //   });
    //   return list;
    // },
  },
  methods: {
    curentAd(index) {
      // return this.adListT.find((el) => el.index === index);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  &.no-data-wrapper {
    margin-top: 20%;
  }
}

.list {
  background: #f0f5f7;

  .ad-item {
    margin-bottom: 0.3rem;
  }
  .job-item-wrapper {
    border-bottom: 0.01rem solid #e5e5e5;
  }
  .split {
    width: 6.9rem;
    height: 0.01rem;
    background: #e6e6e6;
  }
}
.no-data {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 0 1rem;
  top: 50%;
  .no-wrapper {
    width: 100%;
  }
}
.no-more {
  text-align: center;
  color: #999;
  line-height: 0.6rem;
  font-size: 0.28rem;
}
.loading {
  text-align: center;
  color: #999;
  line-height: 0.6rem;
  font-size: 0.28rem;
}
.loading-more {
  padding: 0.2rem 0;
}
</style>
