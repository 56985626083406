<!--
 * @Date: 2021-04-26 11:13:09
 * @LastEditTime: 2023-02-05 10:15:00
 * @Description: 培训card  
-->

<template>
  <div
    :class="['item-wrapper', i % 2 == 0 ? 'img-even' : 'img-on']"
    @click="handleClick"
  >
    <div class="content">
      <div class="p1">
        <div class="job-name">{{ data.jobfairName || "" }}</div>
      </div>
      <div class="p2 ellipsis">
        <span class="company-name"
          >参会单位{{ data.cmpyNum || "0" }}家&nbsp;&nbsp;</span
        >
        <span class="company-name">职位需求{{ data.recruitNum || "0" }}人</span>
      </div>
      <!-- <div class="p4 ellipsis">
        <span>地点：{{ data.address || "----" }} </span>
      </div> -->
      <div class="p5 ellipsis">
        <span
          >时间：{{ filterTime(data.beginTime) || "----:--:--:--" }}至
          {{ filterTime(data.endTime) || "----:--:--:--" }}</span
        >
      </div>
    </div>
    <div v-if="data.status == '已结束'" class="delete-message"></div>
    <div v-if="data.status == '进行中'" class="ing-message"></div>
    <div v-if="data.status == '未开始'" class="uning-message"></div>
  </div>
</template>

<script>
// import moment from "moment";
export default {
  data() {
    return {
      fanfeiImg:
        "https://hrbd-mp.oss-cn-beijing.aliyuncs.com/prod/img/fanfei.png",
      defaultImg:
        "https://hrbd-mp.oss-cn-beijing.aliyuncs.com/prod/img/default.png",
    };
  },
  props: {
    data: Object,
    borderBottom: {
      default: true,
      type: Boolean,
    },
    isConcennBtnShow: {
      type: Boolean,
      default: true,
    },
    i: {
      type: Number,
    },
  },
  filters: {
    numToIntW: (num) => {
      if (!num || num == "undefined") return 0;
      if (num.toString().length > 4) {
        return (num / 10000).toFixed(1) + "w";
      } else {
        return Number(num).toFixed(0) || 0;
      }
    },
    //数字取整
    numToInt: (value) => {
      if (!value) return 0;
      return Number(value).toFixed(0);
    },
  },
  computed: {},
  mounted() {},
  methods: {
    filterTime(val) {
      // return moment(val).format("l").replace(/\//g, "-");
      if (val) {
        return val.split(" ")[0];
      } else {
        return "----:--:--:--";
      }
    },
    handleClick() {
      this.data.jobfairCode;
      this.$router.push(`/jobfair/${this.data.jobfairCode}`);
    },
    getSalaryText() {
      return getSalaryText(this.data);
    },
  },
};
</script>
<style lang="scss" scoped>
.item-wrapper {
  display: flex;
  box-sizing: border-box;
  font-size: 0.3rem;
  color: #333;
  background-color: #fff;
  overflow: hidden;
  box-sizing: border-box;
  // background: url("../../assets/1.jpg") no-repeat;
  padding: 0.3rem;
  border-radius: 0.1rem;
  height: 2.4rem;
  position: relative;
}
.img-even {
  background: url("../../assets/1.jpg") no-repeat;
  background-size: 100% 100%;
}
.img-on {
  background: url("../../assets/2.jpg") no-repeat;
  background-size: 100% 100%;
}

.content {
  flex: 1;
  overflow: hidden;
  border-radius: 0 0 0.16rem 0.16rem;
  position: relative;
  .ellipsis {
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
    letter-spacing: 0.02rem;
  }
}
.p1 {
  margin-bottom: 0.1rem;
  margin-top: 0.04rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.5rem;
  .job-name {
    color: #fff;
    font-size: 0.34rem;
    line-height: 0.4rem;
    overflow: hidden;
    font-weight: bold;
    flex: 1;
    letter-spacing: 0.02rem;

    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.p2 {
  font-size: 0.24rem;
  color: #999999;
  line-height: 0.33rem;
  opacity: 0.7;
}

.p3 {
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;

  .tag {
    margin-right: 0.2rem;
    padding: 0.01rem 0.08rem;
    border-radius: 0.08rem;
    border: 1px solid #999;
    font-size: 12px;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    line-height: 0.28rem;
    &:nth-last-of-type(1) {
      margin-right: 0;
    }
  }
}

.p4 {
  color: #999;
  font-size: 0.24rem;
  color: #999999;
  line-height: 0.28rem;
  margin-bottom: 0.09rem;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
}
.p5 {
  bottom: 0;
  position: absolute;
  color: #999;
  font-size: 0.24rem;
  color: #999999;
  line-height: 0.28rem;
  margin-bottom: 0.09rem;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
  margin-top: 0.6rem;
  opacity: 0.7;
}

.delete-message {
  position: absolute;
  right: -2px;
  top: 0rem;
  background: url("../../assets/complete.png") no-repeat;
  width: 1.1rem;
  height: 1.1rem;
  background-size: 100% 100%;
}
.ing-message {
  background: #f84c4c;
  position: absolute;
  right: -2px;
  top: 0rem;
  background: url("../../assets/ing.png") no-repeat;
  width: 1.1rem;
  height: 1.1rem;
  background-size: 100% 100%;
}
.uning-message {
  background: #51b06c;
  position: absolute;
  right: -2px;
  top: 0rem;
  background: url("../../assets/noStart.png") no-repeat;
  width: 1.1rem;
  height: 1.1rem;
  background-size: 100% 100%;
}
</style>
