<template>
  <span class="icon-wrapper">
    <svg
      aria-hidden="true"
      :style="{
        width: size + 'rem',
        height: size + 'rem',
      }"
    >
      <use :xlink:href="`#${icon}`"></use>
    </svg>
  </span>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    size: {
      type: Number,
      default: 0.5, //rem
    },
    icon: {
      type: String,
      // default: "rc-ico_yishenggongzuozhan_yijiashenhe", //rem
    },
  },
};
</script>
<style scoped lang="scss">
.icon-wrapper {
  font-size: 0;
}
</style>