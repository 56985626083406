var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'item-wrapper',
    _vm.borderBottom ? '' : 'no-border',
    _vm.data.approveType !== undefined && _vm.data.approveType !== 3
      ? 'job-item-delete'
      : '',
  ],on:{"click":_vm.handleClick}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"p1"},[_c('div',{staticClass:"job-name ellipsis"},[_vm._v(_vm._s(_vm.data.jobName || ""))]),_c('div',{staticClass:"money ellipsis"},[_vm._v(_vm._s(_vm.getSalaryText()))])]),_c('div',{staticClass:"p2 ellipsis"},[_c('span',{staticClass:"company-name"},[_vm._v(_vm._s(_vm.data.cmpyName || ""))])]),(_vm.data.reducingConditions && _vm.data.reducingConditions.length)?_c('div',{staticClass:"p3"},[_vm._l((_vm.data.reducingConditions),function(item,index){return [(index < 3)?_c('div',{key:index,staticClass:"tag"},[_vm._v(" "+_vm._s(item)+" ")]):_vm._e()]})],2):_vm._e(),_c('div',{staticClass:"p4 ellipsis"},[_c('span',[_vm._v(_vm._s(_vm.data.cityName || "")+" "+_vm._s(_vm.data.countyName || ""))])])]),(_vm.data.approveType !== undefined && _vm.data.approveType !== 3)?_c('div',{staticClass:"delete-message"},[_c('span',[_vm._v("已下架")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }